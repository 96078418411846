import { IconButton } from '@mui/material';
import { title, desc } from '../../../utils/reportInfo';
import BasicModal from '../../modal/modal';
import Button from '@mui/material/Button';
import CorLogo from '../../../assets/img/CoreLife.jpg';
import MozLogo from '../../../assets/img/Mozzaz Powered Logo Color.png';
import React, { useEffect } from 'react';

const Header = (props: any) => {
  // console.log(props);
  const [openModal, setOpenModal] = React.useState(false);

  useEffect(() => { }, [props]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <h2 style={{ margin: 0 }}>{props.title}</h2>
        {props.subTitle && <h3 style={{ margin: 0 }}>{props.subTitle}</h3>}
      </div>

      <BasicModal open={openModal} setOpen={setOpenModal} title={title()} desc={desc()} ></BasicModal>

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

          <img src={CorLogo} style={{ marginRight: 15, width: "133px" }} alt='CoreLife' title='CoreLife'></img>
          <img src={MozLogo} style={{ marginRight: 15, width: "133px" }} alt='Powered by Mozzaz' title='Powered by Mozzaz'></img>

          <Button
            sx={{
              marginRight: 1,
              height: '40px',
              fontSize: '20px',
              fontFamily: 'ProximaNovaSoftSemibold',
            }}
            variant='text'
            onClick={() => {
              setOpenModal(true);
            }}>Info</Button>

          <Button
            sx={{
              marginRight: 1,
              height: '40px',
              fontSize: '20px',
              fontFamily: 'ProximaNovaSoftSemibold',
            }}
            variant='contained'
            onClick={() => {
              props.clickOnExport();
            }}
          >
            Export
          </Button>

          <Button
            sx={{
              marginRight: 1,
              height: '40px',
              fontSize: '20px',
              fontFamily: 'ProximaNovaSoftSemibold',
            }}
            variant='contained'
            onClick={() => {
              props.clearAllFilters();
            }}
          >
            Clear Filters
          </Button>

          <Button
            sx={{
              marginRight: 3,
              height: '40px',
              fontSize: '20px',
              fontFamily: 'ProximaNovaSoftSemibold',
            }}
            variant='contained'
            onClick={() => {
              props.clickOnRefresh();
            }}
            disabled={props.refreshBtnDisable}
          >
            {props.refreshBtnDisable ? 'Refreshing' : 'Refresh'}
          </Button>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}>
          <h3 style={{ margin: 0 }}>{props.refreshed && <>Last Refreshed</>} </h3>
          <h3 style={{ margin: 0 }}>{props.refreshed}</h3>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Header);