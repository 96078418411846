import dayjs from "dayjs";

export function title() {
  let url = window.location.href.toLowerCase();

  if (url.includes('patientnavigator2')) {
    return 'Historic Lead Report Details:';
  }
  else if (url.includes('patientnavigator')) {
    return 'New Lead Report Details:';
  }
  else if (url.includes('vobnpa')) {
    return 'VOB NPA Report Details:';
  }
  else if (url.includes('vobreverify')) {
    return 'VOB Reverify Report Details:';
  }
  else if (url.includes('vobunable')) {
    return 'VOB Unable Report Details:';
  }
  else if (url.includes('accountlookup')) {
    return 'Account Lookup Report Details:';
  }
  else if (url.includes('om-dailyaudit')) {
    return 'Daily Audit Report Details:';
  }
  else if (url.includes('om-confirmation')) {
    return 'NPA Confirmation Calls Report Details:';
  }
  else if (url.includes('om-past')) {
    return 'Follow Up In Past Report Details:';
  }
  else if (url.includes('om-volume')) {
    return 'Volume Report Details:';
  }
  else if (url.includes('om-restart')) {
    return 'Restart Report Details:';
  }else if (url.includes('om-incompletemovement')) {
    return 'Incomplete Movement Consult Report Details:';
  }
  else if (url.includes('om-abnneeded')) {
    return 'ABN Needed Report Details:';
  }else if (url.includes('om-activewithoutnfv')) {
    return 'Active Without Next Follow-Up Report Details:';
  }else if (url.includes('om-npafaxed')) {
    return 'NPA to be Faxed Report Details:';
  }else if (url.includes('om-unabletoverify')) {
    return 'Unable to Verify Insurance (Leads) Report Details:';
  }else if (url.includes('om-npabeforetoday')) {
    return 'NPA Before Today (Leads) Report Details:';
  }else if (url.includes('om-bhstatus')) {
    return 'Behavioral Health Report Details:';
  }else if (url.includes('om-missingbhpaperwork')) {
    return 'Missing BH Paperwork Report Details:';
  }else if (url.includes('om-unblverifymanagedins')) {
    return 'Unable to Verify Insurance (Managed) Report Details:';
  }else if (url.includes('om-parequired')) {
    return 'PA Required Report Details:';
  }else if (url.includes('newpatientform')) {
    return 'NPA form Links Report Details:';
  }
  
  return '';
}

export function desc() {
  let url = window.location.href.toLowerCase();

  if (url.includes('patientnavigator2')) {
    return <div>This report shows older leads in the system based on the pre-filters below. An "Account Creation Date" picker is included, allowing you to set your own date queries for narrowing in on leads to work on.
      <br />
      <br />
      Pre-filters:
      <ul>
        <li>Patient Lead Status = "New Lead" or "Contacted".</li>
        <li>Outreach Call Attempts = "PN Call 1", "PN Call 2", "Office Call 1", "Office Call 2".</li>
        <li>Sorted by patient lead created date (newest first).</li>
        <li>Defaulted to Account Creation Date from 91 days to the past 6 months.</li>
      </ul>
    </div>;
  }
  else if (url.includes('patientnavigator')) {
    return <div>This report is a real-time report showing fresh leads in the system that have been created within the last 90 days.
      <br />
      <br />
      Pre-filters:
      <ul>
        <li>Patient Lead Status = "New Lead" or "Contacted".</li>
        <li>Outreach Call Attempts = "PN Call 1", "PN Call 2", "Office Call 1", "Office Call 2".</li>
        <li>Sorted by patient lead created date (newest first).</li>
        <li>Account Creation Date based on the current date to 90 days ago ("0-90" day window).</li>
      </ul>
    </div>;
  }
  else if (url.includes('vobnpa')) {
    return <div>The VOB NPA Worklist has been pre-filtered on the following:<br />
      <ul>
        <li>Managed Patient = "No".</li>
        <li>Verification Owner = "Blank".</li>
        <li>Insurance Captured = "Yes".</li>
        <li>Insurance Verified = "Blank" or "No".</li>
        <li>NP Visit Scheduled = "Yes".</li>
        <li>Data fetched for last 60 days.</li>
      </ul>
    </div>;
  }
  else if (url.includes('vobreverify')) {
    return <div>The VOB Reverify Worklist has been pre-filtered on the following:<br />
      <ul>
        <li>Insurance Verified = "Reverify".</li>
        <li>Data fetched for last 60 days.</li>
      </ul>
    </div>;
  }
  else if (url.includes('vobunable')) {
    return <div>The VOB Unable Worklist has been pre-filtered on the following:<br />
      <ul>
        <li>Insurance Verified = "Unable".</li>
        <li>Data fetched for last 60 days.</li>
      </ul>
    </div>;
  }
  else if (url.includes('accountlookup')) {
    return <div>This report allows you to quickly search and identify any patient account in Mozzaz. This includes searching across all active and inactive accounts. Use this report to quickly identify a patient and the clinic they belong to and open the patient's PIS form.<br />
      <ul>
        <li>Data refreshes automatically every 5 minutes.</li>
        <li>On-demand refresh is available with the Refresh button.</li>
        <li>There are no pre-set filters in the query.</li>
        <li>Hyperlinking to the PIS form has been enabled for saved PIS entries.</li>
        <li>If a patient does not have a saved PIS entry a message will be appear on the screen.</li>
      </ul>
    </div>;
  }
  else if (url.includes('om-dailyaudit')) {
    let startDate = dayjs(Date.now()).format('YYYY/MM/DD');
    return <div>This report is used to identify patients whose Next Follow-up Visit Date is today ({startDate}).<br />
    </div>;
  }
  else if (url.includes('om-confirmation')) {
    const startDate = dayjs(Date.now()).format('YYYY/MM/DD');
    const endDate = dayjs(Date.now()).add(32, 'day').format('YYYY/MM/DD');
    return <div>This report shows leads with an NP Visit-Date within the next 31 days ({startDate} - {endDate}).<br />
    </div>;
  }
  else if (url.includes('om-past')) {
    let startDate = dayjs(Date.now()).format('YYYY/MM/DD');
    return <div>This report shows managed patients with a Next Follow Up Visit before today ({startDate}).<br />
    </div>;
  }
  else if (url.includes('om-volume')) {
    return <div>This report shows managed patients whose Managed Patient Status is Active or Active – 24 Hours Scheduling Only.<br />
    </div>;
  }
  else if (url.includes('om-restart')) {
    return <div>This report shows managed patients whose Managed Patient Status is Established Restart.<br />
    </div>;
  }
  else if (url.includes('om-incompletemovement')) {
    return <div>This report shows managed patients whose Managed Consult Status is Incomplete, Scheduled, or Blank.<br />
    </div>;
  }
  else if (url.includes('om-abnneeded')) {
    return <div>This report shows managed patients whose MNT Pathway is Yes and ABN is blank.<br />
    </div>;
  }else if (url.includes('om-activewithoutnfv')) {
    return <div>This report shows managed patients whose Next Follow-Up Visit Date is blank and Managed Patient Status is Active.<br />
    </div>;
  }else if (url.includes('om-npafaxed')) {
    return <div>This report shows managed patients whose Lead Source is Referral by Healthcare Professional and Initial Encounters Sent is No or blank.<br />
    </div>;
  }else if (url.includes('om-unabletoverify')) {
    return <div>This report shows leads whose Insurance Verified is Unable.<br />
    </div>;
  }else if (url.includes('om-npabeforetoday')) {
    return <div>This report shows leads whose Patient Lead Status is NP Visit Scheduled and NP Visit Date is before today.<br />
    </div>;
  }else if (url.includes('om-bhstatus')) {
    return <div>This report shows managed patients whose Managed Patient Status is Active or Active – 24 Hour Scheduling Only.<br />
    </div>;
  }else if (url.includes('om-missingbhpaperwork')) {
    return <div>This report shows managed patients with a BH Initial Visit Date in the next 30 days and whose BH Status is Yes or blank.<br />
    </div>;
  }else if (url.includes('om-unblverifymanagedins')) {
    return <div>This report shows managed patients whose Insurance Verified in Unable.<br />
    </div>;
  }else if (url.includes('om-parequired')) {
    return <div>This report shows managed patients whose Pre-Visit Insurance Requirements is Pre-Auth BH, Pre-Auth MNT, Pre-Auth NPA, or Pre-Auth RMR.<br />
    </div>;
  }else if (url.includes('newpatientform')) {
    return <div>This is a real-time report showing all patients with NPA’s scheduled today and in the next 16 weeks with with a link to their NP Intake Form along with the results if the form has been submitted.<br />
    <ul>
      <li>Users can copy the Intake form link and share it with patients for them to complete.</li>
      <li>Once submitted, users can click “Results” to view form results, right-click to print-to-pdf then upload to the patient’s chart in the EHR.</li>
    </ul></div>;
  }

  return '';
}
